$(document).ready(function ($) {
    $('.box-cart-add').click(function (e) {
        e.stopPropagation();
    });
});

$(document).ready(function ($) {
    $(".table-row").click(function () {
        window.document.location = $(this).data("href");
    });
});

$('#dropdownCart').hover(function () {
    $('#dropdownList', this).toggleClass('d-none');
});